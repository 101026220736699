const $ = jQuery;

export default class ManageConsent {
	constructor(options = null) {}

	init() {
		this.__manageConsent();
	}

	__manageConsent() {
		$('body').on('click', '.tessellateConsent', function (event) {
			event.preventDefault();
			$('.cs-info-sticky-button').click();
		});
	}
}
