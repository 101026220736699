const $ = jQuery;

var courses = '';

if ($('#calendar').length > 0 || $('#mini-calendar').length > 0) {
	$.ajax({
		url: site_data.adminajax,
		type: 'post',
		data: {
			action: 'get_events',
		},
		success: function (response) {
			$.each(response.data, function (i, event) {
				var timePattern = /(\d{1,2}:\d{2}\s(?:am|pm))/i;
				var match = event.end.match(timePattern);

				if (match) {
					var end = match[0];
				} else {
					var end = event.end;
				}

				console.log('start', event.starttime);
				console.log('end', end);

				if (event.allDay) {
					var appendEndTime = ' All Day';
				} else if (event.starttime == end) {
					var appendEndTime = event.starttime;
				} else {
					var appendEndTime = event.starttime + event.end;
				}

				if (event.lastDay != '') {
					var appendEndTime = event.lastDay + event.end;
				}

				const eventItem =
					'<a class="event-item" data-timestamp="' +
					event.starttimestamp +
					'" href="' +
					event.url +
					'"><div class="event-item__title">' +
					event.title +
					'</div><div class="event-item__time">' +
					appendEndTime +
					'</div></a>' +
					'</div>';

				const $dayColumn = $(
					'#calendar .t-calendar__month-container[data-id="' +
						event.startmonth +
						'"][data-year="' +
						event.startyear +
						'"] .day[data-id="' +
						event.startday +
						'"]'
				);

				$dayColumn.addClass('hasevent');
				$dayColumn.find('.t-calendar__event-container').append(eventItem);
			});

			$('#calendar .loading').fadeOut();
		},
	});

	$('.prev-month').click(function () {
		if ($('.t-calendar__month-container.active').prev('.t-calendar__month-container').length) {
			$('.t-calendar__month-container.active')
				.removeClass('active')
				.prev('.t-calendar__month-container')
				.addClass('active');
		}
	});

	$('.next-month').click(function () {
		if ($('.t-calendar__month-container.active').next('.t-calendar__month-container').length) {
			$('.t-calendar__month-container.active')
				.removeClass('active')
				.next('.t-calendar__month-container')
				.addClass('active');
		}
	});
}
