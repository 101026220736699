const $ = jQuery;

const defaultOptions = {
	tags: {
		paneToggle: 'data-pane_toggle',
		paneToggleDep: 'data-pane_toggle-dep',
	},
};

export default class searchaPerson {
	tags;
	constructor(options = null) {
		this.tags = { ...defaultOptions.tags, ...options?.tags };
		this.$searchform = $('div.form--search');
		this.$searchformdep = $('div.form--searchdep');
		this.$activetabs = $('.button--toggle');
		this.$activetabsdep = $('.button--toggledep');
	}

	init() {
		this.#listen();
	}

	initiate() {
		this.#listendep();
	}

	#listen() {
		//pane toggle
		this.$searchform.find('*[' + this.tags.paneToggle + ']').on('click', (event) => {
			const $currentTarget = $(event.currentTarget);

			if (!$currentTarget.hasClass('active')) {
				this.$activeForm = $currentTarget.closest('[' + this.tags.form + ']');

				const $activeButton = this.$activeForm
					.find('[' + this.tags.paneToggle + ']')
					.filter((_, button) => {
						return $(button).hasClass('active');
					});

				this.$activetabs.removeClass('active');
				$currentTarget.addClass('active');
			}

			let $pane = $currentTarget.attr(this.tags.paneToggle);

			$('.typePane').hide();
			$('#' + $pane).show();
		});
	}

	#listendep() {
		//pane toggle
		this.$searchformdep.find('*[' + this.tags.paneToggleDep + ']').on('click', (event) => {
			const $currentTarget = $(event.currentTarget);
			if (!$currentTarget.hasClass('active')) {
				this.$activeForm = $currentTarget.closest('[' + this.tags.form + ']');

				const $activeButton = this.$activeForm
					.find('[' + this.tags.paneToggleDep + ']')
					.filter((_, button) => {
						return $(button).hasClass('active');
					});

				this.$activetabsdep.removeClass('active');
				$currentTarget.addClass('active');
			}

			let $pane = $currentTarget.attr(this.tags.paneToggleDep);

			$('.depPane').hide();
			$('#' + $pane).show();
		});
	}
}
