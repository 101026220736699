export default function autoPlayYouTubeModal() {
	jQuery(($) => {
		const trigger = $('.video-embed__toggle-container');
		trigger.on('click', (event) => {
			const $target = $(event.currentTarget);

			var theModal = $target.data('bs-target');
			var videoSRC = $target.data('src');

			let result = videoSRC.includes('vimeo.com');

			if (!result) {
				var videoSRCauto =
					videoSRC +
					'?autoplay=1&mute=0&color=white&controls=1&modestbranding=1&playsinline=1&rel=0&enablejsapi=1';

				$(theModal + ' iframe').attr(
					'allow',
					'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				);
				$(theModal + ' iframe').attr('src', videoSRCauto);
				$(theModal).on('hidden.bs.modal', function (e) {
					$(theModal + ' iframe').attr('src', '');
				});
			} else {
				// $(theModal + ' iframe').attr('allow', 'autoplay; fullscreen; picture-in-picture');
				var iframe = $(theModal + ' iframe');
				const player = new Vimeo.Player(iframe);
				player.setCurrentTime(0);
				player.play();
			}
		});
	});
}
