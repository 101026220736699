const $ = jQuery;

export default class AjaxPost {
	constructor() {
		this.$container = $('.ajax-filter-container');
		this.$filter = $('.filter');
		this.$pageInfo = $('.page-info');
		this.filters = [];
		this.filtersVisible = [];
		this.postType = null;
		this.postsPerPage = 4;
		this.page = 1;
		this.searchTerm;
		this.pageView;
	}

	init() {
		this.postType = this.$container.attr('data-post-type');
		this.postsPerPage = this.$container.attr('data-posts-per-page');
		this.__initiliseFilters();
		this.__listen();
	}

	__initiliseFilters() {
		this.$filter.find('input').each((_, value) => {
			const $target = $(value);
			if ($target.is(':checked')) {
				this.filters = [...this.filters, $target.val()];
			}
		});
	}

	__listen() {
		$('body').on('change', '[data-ajax-post-hide]', (event) => {
			event.preventDefault();
			const $target = $(event.currentTarget);

			if ($target.is(':checked')) {
				this.filtersVisible = [...this.filtersVisible, $target.val()];
			} else {
				this.filtersVisible = this.filtersVisible.filter((filter) => {
					return filter !== $target.val();
				});
			}

			this.__hideResults({
				filters: this.filtersVisible,
			});
		});

		$('body').on('change', '[data-ajax-search-filter]', (event) => {
			event.preventDefault();

			const $target = $(event.currentTarget);

			if ($target.is(':checked')) {
				this.filters = [...this.filters, $target.val()];
			} else {
				this.filters = this.filters.filter((filter2) => {
					return filter2 !== $target.val();
				});
			}

			this.searchTerm = $target.attr('data-search-term');
			this.pageView = $target.attr('data-pageview');

			this.__getSearchResults();
		});

		$('body').on('change', '[data-ajax-post-filter]', (event) => {
			event.preventDefault();
			const $target = $(event.currentTarget);

			this.page = 1;

			if ($target.is(':checked')) {
				this.filters = [...this.filters, $target.val()];
			} else {
				this.filters = this.filters.filter((filter) => {
					return filter !== $target.val();
				});
			}
			this.__getPosts();
		});

		$('body').on('change', '.filter-item__form input', (event) => {
			event.preventDefault();

			const $target = $(event.currentTarget);

			$($target).closest('.filter-item__form').submit();
		});

		// $('body').on('click', '.ajax-filter-container .pagination button', (event) => {
		// 	event.preventDefault();
		// 	this.page = $(event.currentTarget).attr('data-page');
		// 	this.__getPosts({});
		// });
	}

	__hideResults(options) {
		const hrArray = [];
		let lastElement = '';

		$('.resultsFromSearch>section').each(function () {
			const sectionType = $(this).attr('data-sectiontype');

			$('.resultsFromSearch>section hr').show();

			if (options.filters != '') {
				if ($.inArray(sectionType, options.filters) !== -1) {
					$(this).show();
					hrArray.push(sectionType);
				} else {
					$(this).hide();
				}
			} else {
				$(this).show();
			}

			lastElement = sectionType;
		});

		if (hrArray.length === 0) {
			hrArray.push(lastElement);
		}

		let lastItem = hrArray[hrArray.length - 1];

		$('.resultsFromSearch>section[data-sectiontype="' + lastItem + '"] hr').hide();
	}

	__getSearchResults(options) {
		var pageView = this.pageView;

		$.ajax({
			type: 'POST',
			url: site_data.adminajax,
			data: {
				action: 'ajax_search_posts_call',
				filter: this.filters,
				pageView: this.pageView,
				searchterm: this.searchTerm,
			},
			dataType: 'json',
			encode: true,
		})
			.success(function (response) {
				if (pageView === undefined) {
					$('.ajax-pages-container').html(
						response.data.page.showing + response.data.page.output
					);
					$('.ajax-events-container').html(
						response.data.event.showing + response.data.event.output
					);
					$('.ajax-news-container').html(
						response.data.post.showing + response.data.post.output
					);
					$('.ajax-vacancies-container').html(
						response.data.vacancies.showing + response.data.vacancies.output
					);
				} else {
					$('.pagination').empty();
					$('.ajaxResponseSinglePage').html(
						response.data.page.showing +
							response.data.page.output +
							response.data.pagination.output
					);
					$('.ajaxResponseSingleEvent').html(
						response.data.event.showing +
							response.data.event.output +
							response.data.pagination.output
					);
					$('.ajaxResponseSinglePost').html(
						response.data.post.showing +
							response.data.post.output +
							response.data.pagination.output
					);
					$('.ajaxResponseSingleVacancy').html(
						response.data.vacancies.showing +
							response.data.vacancies.output +
							response.data.pagination.output
					);
				}
			})
			.error(function (error) {
				console.log('error', error.responseText);
			});
	}

	__getPosts(options) {
		console.log({
			action: 'view_ajax_posts',
			filter: this.filters,
			page: this.page,
			post_type: this.postType,
			posts_per_page: this.postsPerPage,
			is_page_info: this.$pageInfo.length > 0 ? 1 : 0,
		});
		$.ajax({
			type: 'POST',
			url: site_data.adminajax,
			data: {
				action: 'view_ajax_posts',
				filter: this.filters,
				page: this.page,
				post_type: this.postType,
				posts_per_page: this.postsPerPage,
				is_page_info: this.$pageInfo.length > 0 ? 1 : 0,
			},
			dataType: 'json',
			encode: true,
		})
			.success(function (response) {
				console.log('res', response);
				$('.ajax-filter-container').html(response.data.output);
			})
			.error(function (error) {
				console.log('error', error.responseText);
			});
	}
}
