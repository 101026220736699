const $ = jQuery;

export default function toggleSlide($toggleButtons) {
	// default hide all target elements unless specified
	$('*[data-toggle-target]:not([data-toggle-show])').hide();

	//toggle target when toggle button is clicked
	$toggleButtons.on('click', (event) => {
		const $currentTarget = $(event.currentTarget);
		const toggleTargetName = $currentTarget.attr('data-toggle');
		const $target = $('*[data-toggle-target=' + toggleTargetName + ']');
		let search = true;

		if ($currentTarget.hasClass('hamburger--page-menu')) {
			$('#sidebar').toggle();
			search = false;
		}

		const $siblingButtons = $(
			'*[data-toggle-parent=' + $currentTarget.attr('data-toggle-parent') + ']'
		);

		$siblingButtons.each((_, button) => {
			const $siblingButton = $(button);
			$siblingButton.removeClass('active');
			const toggleTargetName = $siblingButton.attr('data-toggle');
			const $target = $('*[data-toggle-target=' + toggleTargetName + ']');
			$target.toggleClass('active');
			$target.slideToggle();
		});

		if (search == true) {
			$currentTarget.toggleClass('active');
			$target.slideToggle();
		}
	});
}
