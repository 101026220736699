export default function audio() {
	jQuery(($) => {
		const trigger = $('.t-card__audio');
		trigger.on('click', (event) => {
			const $target = $(event.currentTarget);
			const $parent = $target.parent();
			const $audioPlayer = $parent.children('.audioPlayer');

			const audioUrl = $target.data('audiourl');
			const source = $audioPlayer.children('source');

			// Update the src attribute
			source.attr('src', audioUrl);

			// You might need to load the new audio source
			$audioPlayer[0].load();

			$audioPlayer[0].play();

			$parent.find('.t-card__audio').removeClass('t-card__audio--active');
			$target.addClass('t-card__audio--active');
		});
	});
}
