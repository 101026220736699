jQuery(function ($) {
	//disable scroll script
	var lastWindowScrollTop = 0,
		userAgent = navigator.userAgent,
		$body = $('body'),
		isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent),
		NO_SCROLL_CLASS = isMobile ? 'ios-noscroll' : 'non-ios-noscroll';

	function fixedBody() {
		if (isMobile) {
			lastWindowScrollTop = $(window).scrollTop();
			$body.addClass(NO_SCROLL_CLASS);
			$body.css('top', '-' + lastWindowScrollTop + 'px');
		} else {
			$body.addClass(NO_SCROLL_CLASS);
		}
	}

	function looseBody() {
		$body.removeClass(NO_SCROLL_CLASS);
		if (isMobile) {
			$body.css('top', '');
			window.scrollTo(0, lastWindowScrollTop);
		}
	}

	$.fn.scrollableOverlay = function () {
		this.on('show', fixedBody);
		this.on('hide', looseBody);
	};

	//fixed menu header
	// Get the element with class 'header__main-menu-container'
	var $menuContainer = $('.header__main-menu-container');
	var $header = $('.banner');

	// Function to check the position of the element relative to the window on scroll
	function checkMenuPosition() {
		// Check if the element exists on the page
		if ($menuContainer.length > 0) {
			// Check if the top position of the element relative to the window is at the top of the window or beyond
			if ($menuContainer.offset().top - $(window).scrollTop() <= 0) {
				// The element is at the top of the window or beyond
				$menuContainer.addClass('fixed');
				$('header').height('193px');

				$('.scrolled-search').addClass('show');
			}
		}
	}

	function resetMenuPosition() {
		if ($header.offset().top - $(window).scrollTop() >= 60) {
			if ($menuContainer.hasClass('fixed')) {
				$menuContainer.removeClass('fixed');
				$('header').height('auto');
				$('.scrolled-search').removeClass('show');
				$('.fixed-nav-search').hide();
			}
		}
	}

	// Call the checkMenuPosition function on document ready
	checkMenuPosition();

	$('body').on('click', '.search-on-scroll', function (event) {
		event.preventDefault();
		$('.fixed-nav-search').slideToggle();
	});

	// Attach the checkMenuPosition function to the scroll event
	$(window).scroll(checkMenuPosition);
	$(window).scroll(resetMenuPosition);
	// menu

	// fire function to check if open or closed to fix scrolling
	$('#menuPageMobile').scrollableOverlay();

	$('body').on('click', '.hamburger--main-menu', function (event) {
		$(this).toggleClass('active');

		$('.hamburger__text').toggleClass('hide');

		if ($(this).hasClass('active')) {
			$('.slideOpen').queue(function (next) {
				$(this).height('');
				$(this).removeClass('slideOpen');
				next();
			});

			var totalPageHeight = $(window).height();

			$('body').css('overflow', 'hidden');

			$('#menuPageMobile').slideDown(500, function () {
				// Fade in the child elements
				var distanceFromTop = $(this).offset().top;

				if ($('.header__main-menu-container').hasClass('fixed')) {
					distanceFromTop = 60;
				} else {
					distanceFromTop = $(this).offset().top;
					$('.sub-menu.mobile').height(top + 'px');

					var distanceFromTop = $menuContainer.offset().top - $(window).scrollTop() + 60;
				}

				var menuHeight = totalPageHeight - distanceFromTop;

				$(this).height(menuHeight + 130 + 'px');

				$(this)
					.find('.appendedMenu')
					.each(function (index) {
						$(this)
							.delay(index * 100)
							.fadeIn(500);
					});

				$('a.menu-item-has-children').next('.sub-menu').addClass('mobile');

				$('#menuPageMobile .mobile').css({
					top: distanceFromTop + 'px',
					height: menuHeight + 'px',
				});

				$('.menu-primary-container').css({
					height: menuHeight + 'px',
				});
			});
		} else {
			$('.slideOpen').animate({ height: '0px' }, { queue: false, duration: 125 });

			$('body').css('overflow', 'auto');

			$('#menuPageMobile').trigger('hide');

			$('.appendedMenu').fadeOut(100);

			$('#menu-primary, #menu-primary-3').fadeOut(100, function () {
				$('#menuPageMobile').slideUp(500, function () {
					// $('#menuPageMobile').css('display','flex')
				});
			});
		}
	});

	$('body').on('click', 'a.menu-item-has-children', function (event) {
		if ($(window).width() < 991) {
			event.preventDefault();
		}

		$(this).next('.sub-menu.mobile').scrollTop(0);
		$(this).next('.sub-menu.mobile').addClass('slideOpen');
	});

	$('body').on('click', '.sub-menu-content .back', function (event) {
		event.preventDefault();

		var $this = $(this);
		$(this)
			.parent()
			.parent()
			.removeClass('slideOpen')
			.delay(500)
			.queue(function (next) {
				$this.parent().scrollTop(0);
				next();
			});
	});

	// close menu on resize
	$(window).on('resize', function () {
		var win = $(this); //this = window

		$('.hamburger__text').removeClass('hide');
		$('.hamburger__text.default').addClass('hide');

		$('body').css('overflow', 'auto');
		$('.sub-menu').removeAttr('style');
		$('#menu-primary').removeAttr('style');
		$('.appendedMenu').removeAttr('style');
		$('.hamburger--main-menu').removeClass('active');
		$('#menuPageMobile').removeAttr('style');

		if (win.width() > 991) {
			$('#menuPageMobile').removeAttr('style');
			$('a.menu-item-has-children').next('.sub-menu').removeClass('mobile');
		} else {
			$('a.menu-item-has-children').next('.sub-menu').addClass('mobile');

			var targetElement = $('main');
			var distanceFromTop = targetElement.offset().top;
			var pageHeight = $(document).height();

			var height = pageHeight - distanceFromTop;

			$('#menuPageMobile .mobile').css({ top: distanceFromTop + 'px', height: height });
		}
	});
});
