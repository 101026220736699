const $ = jQuery;

const defaultOptions = {
	tags: {
		searchFilterReset: 'data-fap-filter-reset',
	},
};

export default class Findperson {
	tags;

	constructor(options = null) {
		this.tags = { ...defaultOptions.tags, ...options?.tags };
		this.$fap_filter = $('.sidebar__filter--fap');
		this.$response = $('.filter_response');
		this.$paginationLink = '.pagination span.pagination__item--data';
		this.$paginationPrev = '.pagination span.prev';
		this.$paginationNext = '.pagination span.next';
		this.$paginationCurrent = '.pagination span.current';
		this.$searchFilterReset = 'data-fap-filter-reset';
		this.$filterResetButton = $('*[' + this.tags.searchFilterReset + ']');
		this.urlParams = new URLSearchParams(window.location.search);
	}

	init() {
		this.__listen();
	}

	__listen() {
		this.$fap_filter.find('input').on('change', (event) => {
			event.preventDefault();
			this.__initiliseFilters();
		});

		this.$filterResetButton.on('click', (event) => {
			this.#resetFilters();
		});

		this.__pagination();
	}

	__changeFilters(options = null) {
		let filters = [];
		let type = [];

		if (options.area.length > 0) {
			filters = options.area;
			type.push('area');
		}

		if (options.department.length > 0) {
			const deparr = [];

			$('.sidebar__filter--fap input[data-fap-filter="department"]').each((_, value) => {
				const $target = $(value);

				if ($target.is(':checked')) {
					deparr.push($target.val());
				}
			});

			if (deparr.length > 0) {
				$('.sidebar__filter--fap input[data-fap-filter="deanery"]').prop('disabled', true);
				$('.sidebar__filter--fap input[data-fap-filter="deanery"]').prop('checked', false);
				$('.sidebar__filter--fap input[data-fap-filter="area"]').prop('disabled', true);
				$('.sidebar__filter--fap input[data-fap-filter="area"]').prop('checked', false);
			} else {
				$('.sidebar__filter--fap input[data-fap-filter="area"]').prop('disabled', false);
			}
			const fap = new Findperson();
			fap.__initiliseFilters(null, true);
		} else {
			$.ajax({
				type: 'POST',
				url: site_data.adminajax,
				data: {
					action: 'ajax_disable_filters',
					filter: filters,
					type: type,
				},
				dataType: 'json',
				encode: true,
			})
				.success(function (response) {
					$('.sidebar__filter--fap input[data-fap-filter="area"]').prop(
						'disabled',
						false
					);
					$('.sidebar__filter--fap input[data-fap-filter="deanery"]').prop(
						'disabled',
						false
					);
					const idsArrayDeanery = response.data.output.deanery;

					if (idsArrayDeanery) {
						$('.sidebar__filter--fap input[data-fap-filter="deanery"]').each(
							(_, value) => {
								const $target = $(value);
								if (idsArrayDeanery) {
									if (idsArrayDeanery.includes($target.val())) {
										$target.prop('disabled', false);
									} else {
										$target.prop('disabled', true);
									}
								}
							}
						);
					}

					const fap = new Findperson();
					fap.__initiliseFilters(null, true);
				})
				.error(function (error) {
					console.log('error', error.responseText);
				});
		}
	}

	__initiliseFilters(options = null, bool = false) {
		//multi dimesional array to push filter values too
		this.fap_filters = {
			area: [],
			deanery: [],
			department: [],
			place_type: [],
			role: [],
		};

		this.$fap_filter.find('input').each((_, value) => {
			const $target = $(value);
			//if checked and data attribute
			if ($target.is(':checked') && $target.attr('data-fap-filter') === 'area') {
				this.fap_filters.area = [...this.fap_filters.area, $target.val()];
			}
			if ($target.is(':checked') && $target.attr('data-fap-filter') === 'deanery') {
				this.fap_filters.deanery = [...this.fap_filters.deanery, $target.val()];
			}
			if ($target.is(':checked') && $target.attr('data-fap-filter') === 'department') {
				this.fap_filters.department = [...this.fap_filters.department, $target.val()];
			}
			if ($target.is(':checked') && $target.attr('data-fap-filter') === 'place-type') {
				this.fap_filters.place_type = [...this.fap_filters.place_type, $target.val()];
			}
			if ($target.is(':checked') && $target.attr('data-fap-filter') === 'role') {
				this.fap_filters.role = [...this.fap_filters.role, $target.val()];
			}
		});

		//change greyed out filters

		//get results using ajax
		if (bool) {
			this.__getSearchResults(this.fap_filters, options);
		} else {
			this.__changeFilters(this.fap_filters);
		}
	}

	#resetFilters() {
		this.$fap_filter.find('input').each((_, value) => {
			const $target = $(value);
			$($target).prop('checked', false);
		});
		this.__getSearchResults();
	}

	__pagination() {
		$('body').on('click', `${this.$paginationPrev}, ${this.$paginationNext}`, (event) => {
			event.preventDefault();
			const target = event.currentTarget;
			const current = $(this.$paginationCurrent).attr('data-page');
			const type = $(this.$paginationCurrent).attr('data-type');

			if ($(target).hasClass('next')) {
				this.page = Number(current) + 1;
			}
			if ($(target).hasClass('prev')) {
				this.page = Number(current) - 1;
			}

			this.__initiliseFilters([this.page, type], true);

			var elementTop = this.$fap_filter.offset().top;
			var windowTop = $(window).scrollTop();

			if (elementTop < windowTop) {
				$('html, body').animate(
					{
						scrollTop: elementTop - 200,
					},
					500
				);
			}
		});

		$('body').on('click', this.$paginationLink, (event) => {
			event.preventDefault();
			const target = event.currentTarget;
			if ($(target).attr('data-page') != null) {
				this.page = $(target).attr('data-page');
				this.type = $(target).attr('data-type');
				this.__initiliseFilters([this.page, this.type], true);

				var elementTop = this.$fap_filter.offset().top;
				var windowTop = $(window).scrollTop();

				if (elementTop < windowTop) {
					$('html, body').animate(
						{
							scrollTop: elementTop - 200,
						},
						500
					);
				}
			}
		});
	}

	__getSearchResults(options, page = null) {
		const keyword = this.urlParams.get('keyword');
		let type = this.urlParams.get('type');

		if (!type) {
			if (page != null) {
				//Dan & Lewis added this,as js was erroring due to null value
				if (page[1]) {
					type = page[1];
				}
			}
		}

		$.ajax({
			type: 'POST',
			url: site_data.adminajax,
			data: {
				action: 'ajax_filter_fap',
				filter: options,
				page: page == null ? 0 : page[0], //Dan & Lewis added this,as js was erroring due to null value
				searchterm: keyword,
				type: type,
			},
			dataType: 'json',
			encode: true,
		})
			.success(function (response) {
				$('.filter_response').html(response.data.output);
			})
			.error(function (error) {
				console.log('error', error.responseText);
			});
	}
}
