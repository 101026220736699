// import js dependencies

import autoPlayYouTubeModal from './video-autoplay';
import audio from './audio';
import toggleSlide from './toggle-slide';
import mapModal from './map-modal';
import './ajax/calendar';
import AjaxPost from './ajax/archive-posts';
import ManageConsent from './manage-consent';
import searchaPerson from './find_a_person_search';
import Findperson from './ajax/find_person';

require('./menu.js');

jQuery(($) => {
	document.querySelectorAll('.video').forEach((element) => {
		element.addEventListener('hidden.bs.modal', (event) => {
			const $target = $(event.currentTarget);

			const iframe = $target.find('iframe')[0];
			let videoSRC = iframe.getAttribute('src');

			let result = videoSRC.includes('vimeo.com');

			if (result) {
				if (iframe) {
					const player = new Vimeo.Player(iframe);
					player.pause().catch((error) => {
						console.error('Error pausing the video:', error);
					});
				}
			}
		});
	});

	import('./question').then(({ question }) => {
		question();
	});

	import('./accessibility').then(({ accessibility }) => {
		accessibility();
	});

	if ($('.swiper').length) {
		import('./swiper').then(
			({ initiliseVideoSliders, initiliseHomeBannerSliders, initiliseTextImageSliders }) => {
				initiliseHomeBannerSliders();
				initiliseTextImageSliders();
				initiliseVideoSliders();
			}
		);
	}

	if ($('svg[data-map-modal]').length > 0) {
		mapModal();
	}

	if ($('*[data-toggle]').length) {
		toggleSlide($('*[data-toggle]'));
	}

	autoPlayYouTubeModal();

	audio();

	const ajaxPost = new AjaxPost();
	ajaxPost.init();

	const manageConsent = new ManageConsent({});
	manageConsent.init();

	const $searchForms = $('form.form--search');
	if ($searchForms.length) {
		const search = new searchaPerson();
		search.init();
	}

	const $searchForms2 = $('form.form--searchdep');
	if ($searchForms2.length) {
		const search2 = new searchaPerson();
		search2.initiate();
	}

	const $fap_results = $('.find-a-person--results');
	if ($fap_results.length) {
		const fap = new Findperson();
		fap.init();
	}

	// DISABLE SCROLL WHEN OVERLAYING PAGE WITH MENU
	// var lastWindowScrollTop = 0,

	// userAgent = navigator.userAgent,
	// $body = $('body'),
	// isIOS = /iPhone|iPad/.test(userAgent),
	// NO_SCROLL_CLASS = isIOS
	// 	? 'ios-noscroll'
	// 	: 'non-ios-noscroll';

	// function fixedBody() {
	// 	if (isIOS) {
	// 		lastWindowScrollTop = $(window).scrollTop();
	// 		$body.addClass(NO_SCROLL_CLASS);
	// 		$body.css('top', '-' + lastWindowScrollTop + 'px');
	// 	} else {
	// 		$body.addClass(NO_SCROLL_CLASS);
	// 	}
	// }

	// function looseBody() {
	// 	$body.removeClass(NO_SCROLL_CLASS);
	// 	if (isIOS) {
	// 		$body.css('top', '');
	// 		window.scrollTo(0, lastWindowScrollTop);
	// 	}
	// }

	// $.fn.scrollableOverlay = function () {
	// 	this.on('show', fixedBody)
	// 	this.on('hide', looseBody)
	// };
});
